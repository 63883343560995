import { Subject } from "rxjs";

export const baseUrl = 'http://skyrep/';
export const reactNav = true;
export const user$ = new Subject({});
export const setting$ = new Subject();
export const persons = [{
    lider: "Maciej Gaczyński",
    name: "POŁUDNIE",
    members: [
        'bozena.ryszewska@exeltis.com',
        'joanna.wojcik@exeltis.com',
        'magdalena.dankiewicz@exeltis.com',
        'monika.gruszka@exeltis.com',
        'marta.mierzwinska@exeltis.com',
        'agnieszka.gorecka@exeltis.com',
        'paulina.jaros@exeltis.com',
        'bartlomiej.kapala@exeltis.com',
        'justyna.kuwik@exeltis.com',
        'magdalena.derszniak@exeltis.com',
    ]
}, {
    lider: "Małgorzata Uścinowicz",
    name: "PÓŁNOC",
    members: [
        'dorota.bednarska@exeltis.com',
        'malgorzata.sawicka@exeltis.com',
        'agnieszka.janiszewska@exeltis.com',
        'marcin.prusinowski@exeltis.com',
        'iza.czesnik@exeltis.com',
        'ewa.tarczykowska@exeltis.com',
        'monika.ziolkowska@exeltis.com',
        'joanna.krystman@exeltis.com',
        'adriana.kupczyk@exeltis.com',
        'marta.slominska@exeltis.com'
    ]
}, {
    lider: "Anna Ostrowska - Kłos",
    name: "CENTRUM",
    members: [
        'anna.ninkiewicz@exeltis.com',
'krzysztof.sobkowicz@exeltis.com',
'iwona.nowak@exeltis.com',
'julita.grzybowska@exeltis.com',
'lukasz.marczuk@exeltis.com',
'kkupinska@chemogroup.net',
'agnieszka.groblewska@exeltis.com',
'katarzyna.mazurek@exeltis.com',
'kamila.komarowska@exeltis.com',
'sandra.kleczek@exeltis.com',
'magdalena.wyzgal@exeltis.com'
    ]
}];